import React from 'react';
import I18n from 'common/i18n';
import './explore-banner.scss';
import { View } from 'common/types/view';
import { ExploreEntry, canRenderExploreEntry } from 'common/exploreEntryHelper';
import { scgcEnabled } from 'common/domain/helpers';
import DismissibleSessionBanner, { BannerType } from 'common/components/DismissibleSessionBanner';

const t = (k: string) => I18n.t(k, { scope: 'shared.explore_banner'});

interface ExploreBannerProps {
  view: View;
}

interface ExploreBannerState {
  canRender: boolean;
}

export default class ExploreBanner extends React.Component<ExploreBannerProps, ExploreBannerState> {
  state = {
    canRender: false
  };

  componentDidMount() {
    this.checkIfCanRender();
  }

  async checkIfCanRender() {
    try {
      this.setState({ canRender: await canRenderExploreEntry(this.props.view) });
    } catch (err) {
      this.setState({ canRender: false });
    }
  }

  render() {
    const { view } = this.props;
    const intro = scgcEnabled() ? t('introducing_explore_scgc') : t('introducing_explore_odp');
    const supportArticle = scgcEnabled() ? 'https://support.socrata.com/hc/en-us/articles/1500012938981' : 'https://support.socrata.com/hc/en-us/articles/4409024688151';
    return this.state.canRender ? (<DismissibleSessionBanner
      sessionStorageKey="exploreEntryBanner"
      alertWhenDismissed={false}
      bannerType={BannerType.Info}
      className="explore-banner">
      <div className="banner-content">
        <ExploreEntry intro={intro} url={`/d/${view.id}/explore`} />
        <a
          href={supportArticle}
          target="_blank"
          className="learn-more-link">
          {t('learn_more')}
        </a>
      </div>
    </DismissibleSessionBanner>) : null;
  }
}
